<template>
  <Auth />
  <div class="py-4 container-fluid">
    <FinanceSummary
      :balance="displayTotalBalance"
      :bill="displayTotalBill"
      :withdraw="displayTotalWithdraw"
      :income_deposit="displayTotalIncomeDeposit"
    />
    <div class="row mt-4">
      <OverviewBoxSaldo
        :is_admin="is_admin"
        :clickable="clickable"
        @viewdetailpackage="handleviewdetailpackage"
      />
    </div>
    <div class="mt-4 row">
      <div v-if="showPackageDetail" class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="mb-0">
                  {{ dataPackageSelected.merchant_order_number }}
                </h5>
                <p class="mb-0 text-sm">
                  {{ dataPackageSelected.to_name }} |
                  {{ dataPackageSelected.to_phone_number }}
                </p>
              </div>
              <div class="col-md-6 text-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="backToPackageHistory"
                >
                  Kembali ke Riwayat Paket
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <ViewPackageDetail
              :data-package="dataPackageSelected"
              @successcallback="handleSuccessCallback"
              @newstatuscallback="handleNewStatusCallback"
            />
          </div>
        </div>
      </div>

      <!-- <div v-if="isLoadingTable" class="col-12">
        <div class="card">
          <div class="card-header">
            <el-skeleton :rows="4" animated />
          </div>
        </div>
      </div> -->

      <div v-if="showTable" class="col-12">
        <div v-if="showAllCODTable" class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Transaksi COD</h5>
            <p class="mb-0 text-sm">Kumpulan Saldo hasil dari COD Transaksi.</p>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                    @input="handleInput"
                  >
                  </el-input>
                </div>
              </div>
              <div class="table-responsive">
                <el-table
                  :data="queriedData"
                  row-key="id"
                  header-row-class-name="thead-light"
                  @selection-change="selectionChange"
                >
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                  >
                  </el-table-column>
                  <el-table-column
                    min-width="70px"
                    align="left"
                    label="Tracking ID"
                  >
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                        >{{ props.row.merchant_order_number }}<br />
                        <small class="text-secondary">
                          {{ props.row.email }}<br />
                          {{ props.row.display_created_at }}<br /> </small
                      ></a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Pengirim"
                  >
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                        >{{ props.row.from_name }}
                        <i
                          v-if="props.row.official_dropship_store_id"
                          class="fas fa-star text-primary"
                          style="font-size: 12px"
                        ></i>

                        <br /><small class="text-secondary"
                          >{{ props.row.from_phone_number }}<br />{{
                            props.row.from_address_1
                          }}<br /><span style="color: #bbbbbb">{{
                            props.row.origin
                          }}</span></small
                        ></a
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Penerima"
                  >
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                        >{{ props.row.to_name }}<br /><small
                          class="text-secondary"
                          >{{ props.row.to_phone_number }}<br />{{
                            props.row.to_address_1
                          }}<br /><span style="color: #bbbbbb">{{
                            props.row.destination
                          }}</span></small
                        ></a
                      >
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50px" align="left" label="COD">
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                      >
                        {{ props.row.display_cod }}<br />
                        <small class="text-success"
                          >+{{ props.row.display_profit }}</small
                        >
                      </a>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50px" align="left" label="Status">
                    <template #default="props">
                      <span v-if="props.row.cronjob === null">
                        <button
                          class="btn btn-success btn-xs"
                          @click.prevent="
                            GoProcessSendPackage(props.$index, props.row)
                          "
                        >
                          Go Process
                        </button>
                      </span>
                      <span v-else class="text-secondary">
                        <TrackParcelStatus
                          :status="props.row.status"
                          :message="props.row.error_message"
                        />
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <br />
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <BillBalance
                      :is_admin="is_admin"
                      @displaygrandtotalbill="handleDisplayGrandTotalBill"
                      size="md"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <IncomeDepositFromAdmin
                      @displaygrandtotalincomedeposit="
                        handleDisplayGrandTotalIncomeDeposit
                      "
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <WithdrawHistoryFromAdmin />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  //ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import ViewPackageDetail from "@/components/ViewPackageDetail.vue";
import TrackParcelStatus from "@/components/TrackParcelStatus.vue";
import OverviewBoxSaldo from "@/components/Finance/OverviewBoxSaldo.vue";
import BillBalance from "@/components/Finance/BillBalance.vue";
import IncomeDepositFromAdmin from "@/components/Finance/IncomeDepositFromAdmin.vue";
import WithdrawHistoryFromAdmin from "@/components/Finance/WithdrawHistoryFromAdmin.vue";
import FinanceSummary from "@/components/Finance/FinanceSummary.vue";
import Swal from "sweetalert2";

export default {
  name: "BalanceFromAdmin",
  components: {
    BasePagination,
    Auth,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    //ElSkeleton,
    ViewPackageDetail,
    TrackParcelStatus,
    OverviewBoxSaldo,
    BillBalance,
    IncomeDepositFromAdmin,
    WithdrawHistoryFromAdmin,
    FinanceSummary,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      // urlread: "/api/v1/balance/admin/cod/all",
      urlread: "/api/v1/balance/admin/cod/success",
      urlgoprocess: "/api/v1/send_packages/admin/goprocess",
      urlread_balance: "/api/v1/report/balance/admin",
      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "merchant_order_number",
        "from_name",
        "from_address_1",
        "origin",
        "to_name",
        "email",
        "to_phone_number",
        "to_address_1",
        "destination",
        "status",
        "display_created_at",
        "cash_on_delivery",
        "display_cod",
      ],

      tableColumns: [
        {
          type: "selection",
        },
      ],
      rawTableData: [],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      is_admin: true,
      isLoading: false,
      loadingTable: true,
      isLoadingTable: false,
      showTable: true,
      showAllCODTable: true,
      showPackageDetail: false,
      dataPackageSelected: [],
      indexActive: "",
      clickable: true,
      displayTotalBalance: "Rp. 0",
      displayTotalBill: "Rp. 0",
      displayTotalWithdraw: "Rp. 0",
      displayTotalIncomeDeposit: "Rp. 0",
      debounceTimer: null
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      return this.tableData;
      // if (!this.searchQuery) {
      //   return this.pagedData;
      // }
      // return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.rawTableData.total;
      // return this.searchAction.length > 0
      //   ? this.searchAction.length
      //   : this.tableData.length;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getTables();
      },
      deep: true
    }
  },
  created() {
    this.getTables();
    this.TotalBalance();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async TotalBalance() {
      try {
        let url = this.urlread_balance;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //this.datas = response.data.data.data;
        this.totalBalance = response.data.data.grandtotal_balance;
        this.displayTotalBalance =
          response.data.data.display_grandtotal_balance;
        this.displayTotalWithdraw =
          response.data.data.display_grandtotal_withdraw_request;

        // show showWithdrawRequest
        this.handleShowWithdrawRequest();

        //console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getTables() {
      try {
        this.loadingService();
        this.isLoadingTable = true;
        this.showTable = true;
        this.showAllCODTable = true;
        this.showPackageDetail = false;
        //this.searchQuery
        let paramURL = '?page=+'+this.pagination.currentPage+'&itemsPerPage='+this.pagination.perPage;
        if(this.searchQuery.length > 0) {
          paramURL = '?page=+'+this.pagination.currentPage+'&itemsPerPage='+this.pagination.perPage+'&search='+this.searchQuery;
        }
        const response = await axios.get(this.urlread+paramURL, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.rawTableData = response.data.data;
        this.datas = response.data.data.data;
        this.tableData = this.datas;
        this.isLoadingTable = false;
        this.showAllCODTable = true;
        this.loadingService().close();
        //console.log(this.datas);
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.isLoadingTable = false;
        this.showTable = true;
        this.errorRedirect500(error);
      }
    },
    handleInput() {
      // Hapus timeout sebelumnya jika ada
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      
      // Setelah pengguna berhenti mengetik, akan menunggu 500 ms sebelum memicu pencarian
      this.debounceTimer = setTimeout(() => {
        this.searchDataTable();
      }, 500);
    },
    searchDataTable() {
      this.getTables();
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    refreshTable() {
      this.getTables();
    },
    async GoProcessSendPackage(index, row) {
      try {
        this.loadingService();
        const response = await axios({
          method: "post",
          url: this.urlgoprocess,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            id: row.id,
            merchant_order_number: row.merchant_order_number,
          },
        });
        console.log(response.data);
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire({
            title: "God Job!",
            html: "Successfully create order.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          this.getTables();
        }

        this.loadingService().close();
      } catch (error) {
        this.loadingService().close();
        console.log(error);
      }
    },

    handleViewPackageDetail(index, row) {
      this.dataPackageSelected = row;
      this.showTable = false;
      this.showPackageDetail = true;
      this.indexActive = index;
    },

    backToPackageHistory() {
      this.showTable = true;
      this.showPackageDetail = false;
      this.indexActive = "";
    },

    handleSuccessCallback(values) {
      if (values == true) {
        Swal.fire({
          title: "God Job!",
          html: "Successfully cancel order.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        this.backToPackageHistory();
        this.getTables();
      }
    },
    handleNewStatusCallback(values) {
      this.datas[this.indexActive].status = values;
      this.tableData[this.indexActive].status = values;
    },
    handleviewdetailpackage(values) {
      this.showPackageDetail = false;
      this.showTable = false;
      this.isLoadingTable = true;
      this.showAllCODTable = false;
      setTimeout(() => {
        this.datas = values;
        this.tableData = this.datas;
        this.isLoadingTable = false;
        this.showTable = true;
        this.showAllCODTable = true;
      }, 1000);
    },
    handleDisplayGrandTotalBill(values) {
      this.displayTotalBill = values;
    },
    handleDisplayGrandTotalIncomeDeposit(values) {
      this.displayTotalIncomeDeposit = values;
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
