<template>
  <Auth />
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h5 class="mb-0">Riwayat Pencairan</h5>
      <p class="mb-0 text-sm">Daftar pencairan yang sudah dibuat.</p>
    </div>
    <div class="card-body" style="border-top: 1px solid #eeeeee">
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            v-model="pagination.perPage"
            class="select-primary pagination-select"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-primary"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <div>
            <el-input
              v-model="searchQuery"
              type="search"
              class="mb-3"
              clearable
              prefix-icon="el-icon-search"
              style="width: 200px"
              placeholder="Search records"
              aria-controls="datatables"
            >
            </el-input>
          </div>
        </div>
        <el-skeleton v-if="loadingTable" :rows="3" animated />
        <div v-else class="table-responsive">
          <el-table
            style="font-size: 12px"
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @selection-change="selectionChange"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              v-bind="column"
            >
            </el-table-column>
            <el-table-column align="left" label="Email">
              <template #default="props">
                {{ props.row.email }}
              </template>
            </el-table-column>
            <el-table-column align="left" label="Bank Info">
              <template #default="props">
                {{ props.row.bank_name }}<br />
                {{ props.row.bank_account_name }}<br />
                {{ props.row.bank_account_number }}
              </template>
            </el-table-column>
            <el-table-column align="left" label="Amount">
              <template #default="props">
                <b>{{ props.row.display_amount }}</b
                ><br />
              </template>
            </el-table-column>
            <el-table-column align="left" label="Created at">
              <template #default="props">
                {{ props.row.display_created_at }}
              </template>
            </el-table-column>
            <el-table-column align="left" label="Status">
              <template #default="props">
                <span
                  class="badge bg-secondary"
                  v-if="props.row.status == 'request'"
                  >{{ props.row.status }}</span
                >
                <span
                  class="badge bg-success"
                  v-if="props.row.status == 'paid'"
                  >{{ props.row.status }}</span
                >
                <span
                  class="badge bg-danger"
                  v-if="props.row.status == 'canceled'"
                  >{{ props.row.status }}</span
                >
              </template>
            </el-table-column>
            <el-table-column align="left" label="Action">
              <template #default="props">
                <a
                  href="#"
                  @click.prevent="handleUpdateAction(props.$index, props.row)"
                  ><i class="fas fa-edit text-primary"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <br />
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
            v-model="pagination.currentPage"
            class="pagination-no-border"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </div>
    </div>
  </div>

  <modal v-model:show="modals.classic">
    <div class="p-2">
      <form @submit.prevent="onSubmit">
        <h6>Data Request Withdraw</h6>
        <div v-if="rowSelected != ''" class="text-sm">
          Nama Bank: <b>{{ rowSelected.bank_name }}</b
          ><br />
          Pemilik Akun: <b>{{ rowSelected.bank_account_name }}</b
          ><br />
          No. Rekening: <b>{{ rowSelected.bank_account_number }}</b
          ><br />
          Jumlah Pencairan: <b>{{ rowSelected.display_amount }}</b
          ><br />
          Status:
          <span
            class="badge bg-secondary"
            v-if="rowSelected.status == 'request'"
            >{{ rowSelected.status }}</span
          >
          <span class="badge bg-success" v-if="rowSelected.status == 'paid'">{{
            rowSelected.status
          }}</span>
          <span
            class="badge bg-danger"
            v-if="rowSelected.status == 'canceled'"
            >{{ rowSelected.status }}</span
          >
          <div class="row mt-4">
            <div class="col-md-6">
              <label class="form-label">Update Status</label>
              <select v-model="form.status" class="form-select form-select-lg">
                <option
                  v-for="select in selects.status"
                  :key="select.value"
                  :value="select.value"
                >
                  {{ select.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <hr style="margin-bottom: -10px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Submit Data</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 25px"
          @click="modals.classic = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import ArgonButton from "@/components/ArgonButton.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "WithdrawHistoryFromAdmin",
  components: {
    BasePagination,
    Auth,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElSkeleton,
    Modal,
    ArgonButton
  },
  data() {
    return {
      urlread: "/api/v1/admin/withdraw_requests/read",
      urlupdate: "/api/v1/admin/withdraw_requests/update",

      modals: {
        classic: false,
      },

      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["amount", "bank_account_name", "bank_account_number", "bank_name", "display_amount", "status", "email", "display_created_at"],
      tableColumns: [],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,

      rowSelected: [],

      selects: {
        status: [
          {
            value: "request",
            name: "Request",
          },
          {
            value: "paid",
            name: "Paid",
          },
          {
            value: "canceled",
            name: "Canceled",
          },
        ],
      },
      form: {
        id: '',
        amount: '',
        status: '',
      },
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
     queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },

    async getTables() {
      try {
        this.loadingTable = true;
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //console.log(response);
        this.datas = response.data.data;
        this.tableData = this.datas;

        this.loadingTable = false;
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
        this.errorRedirect500(error);
      }
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        const response = await axios({
          method: sendmethod,
          url: this.urlupdate,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          //Swal.fire("Good job!", "Data berhasil disimpan!", "success");
          Swal.fire({
            title: "Good job!",
            html: "Data berhasil diupdate!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },

    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    handleUpdateAction(index, row) {
      this.modals.classic = true;
      this.rowSelected = row;
      this.form.status = this.rowSelected.status;
      this.form.id = this.rowSelected.id;
      this.form.amount = this.rowSelected.amount;
    },

    refreshTable() {
      this.getTables();
    },
  },
};
</script>
